import {Dialog} from '../../gollum-dialog'
import {replaceSelection} from '../gollum-editor'

export const Textile = {
  'function-bold': {
    search: /(^[\n]+)([\n\s]*)/g,
    replace: '*$1*$2',
  },

  'function-italic': {
    search: /(^[\n]+)([\n\s]*)/g,
    replace: '_$1_$2',
  },

  'function-hr': {
    append: '\n***\n',
  },

  'function-code': {
    search: /(^[\n]+)([\n\s]*)/g,
    // eslint-disable-next-line github/unescaped-html-literal
    replace: '<pre><code>$1</code></pre>$2',
  },

  'function-ul': {
    search: /(.+)([\n]?)/gi,
    replace: '* $1$2',
  },

  'function-ol': {
    search: /(.+)([\n]?)/gi,
    replace: '# $1$2',
  },

  'function-blockquote': {
    search: /(.+)([\n]?)/gi,
    replace: 'bq. $1$2',
  },

  'function-link': {
    exec() {
      Dialog.init({
        title: 'Insert Link',
        fields: [
          {
            id: 'text',
            name: 'Link Text',
            type: 'text',
            help: 'The text to display to the user.',
          },
          {
            id: 'href',
            name: 'URL',
            type: 'text',
            help: 'The URL to link to.',
          },
        ],
        OK(res) {
          let h = ''
          if (res['text'] && res['href']) {
            h = `"${res['text']}":${res['href']}`
          }
          replaceSelection(h)
        },
      })
    },
  },

  'function-image': {
    exec() {
      Dialog.init({
        title: 'Insert Image',
        fields: [
          {
            id: 'url',
            name: 'Image URL',
            type: 'text',
          },
          {
            id: 'alt',
            name: 'Alt Text',
            type: 'text',
          },
        ],
        OK(res) {
          if (res['url']) {
            let h = `!${res['url']}`
            if (res['alt'] !== '') {
              h += `(${res['alt']})`
            }
            h += '!'
            replaceSelection(h)
          }
        },
      })
    },
  },
}

export const TextileHelp = [
  {
    menuName: 'Phrase Modifiers',
    content: [
      {
        menuName: 'Emphasis / Strength',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To place emphasis or strength on inline text, simply place <code>_</code> (underscores) around the text for emphasis or <code>*</code> (asterisks) around the text for strength. In most browsers, <code>_mytext_</code> will appear as italics and <code>*mytext*</code> will appear as bold.</p><p>To force italics or bold, simply double the characters: <code>__mytext__</code> will appear italic and <code>**mytext**</code> will appear as bold text.</p>',
      },
      {
        menuName: 'Citations / Editing',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To display citations, wrap your text in <code>??</code> (two question marks).</p><p>To display edit marks such as deleted text (strikethrough) or inserted text (underlined text), wrap your text in <code>-</code> (minuses) or <code>+</code> (pluses). For example <code>-mytext-</code> will be rendered as <span style="text-decoration: line-through;">mytext</span> and <code>+mytext+</code> will be rendered as <span style="text-decoration: underline;">mytext</span></p>',
      },
      {
        menuName: 'Superscript / Subscript',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To display superscript, wrap your text in <code>^</code> (carets). To display subscript, wrap your text in <code>~</code> (tildes).</p>',
      },
      {
        menuName: 'Code',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To display monospace code, wrap your text in <code>@</code> (at symbol). For example, <code>@mytext@</code> will appear as <code>mytext</code>.</p>',
      },
      {
        menuName: 'Acronyms',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To create an acronym, suffix the acronym with the definition in parentheses. For example, <code>JS(JavaScript)</code> will be displayed as <abbr title="JavaScript">JS</abbr>.</p>',
      },
    ],
  },
  {
    menuName: 'Block Modifiers',
    content: [
      {
        menuName: 'Headings',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To display a heading in Textile, prefix your line of text with <code>hn.</code>, where <code>n</code> equals the heading size you want (1 is largest, 6 is smallest).</p>',
      },
      {
        menuName: 'Paragraphs / Quotes',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To create a new paragraph, prefix your first line of a block of text with <code>p.</code>.</p><p>To create a blockquote, make sure at least one blank line exists between your text and any surrounding text, and then prefix that block with <code>bq.</code> If you need to extend a blockquote to more than one text block, write <code>bq..</code> (note the two periods) and prefix your next normal paragraph with <code>p.</code></p>',
      },
      {
        menuName: 'Code Blocks',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>Code blocks in textile are simply prefixed like any other block. To create a code block, place the beginning of the block on a separate line and prefix it with <code>bc.</code></p><p>To display a preformatted block, prefix the block with <code>pre.</code></p>',
      },
      {
        menuName: 'Lists',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To create ordered lists, prefix each line with <code>#</code>. To create unordered lists, prefix each line with <code>*</code>.</p>',
      },
    ],
  },
  {
    menuName: 'Links / Images',
    content: [
      {
        menuName: 'Links',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To display a link, put the text you want to display in quotes, then a colon (<code>:</code>), then the URL after the colon. For example <code>&quot;GitHub&quot;:https://github.com/</code> will appear as <a class="Link--inTextBlock" href="javascript:void(0);">GitHub</a>.</p>',
      },
      {
        menuName: 'Images',
        data:
          // eslint-disable-next-line github/unescaped-html-literal
          '<p>To display an image, simply wrap the image’s URL in <code>!</code> (exclamation points). If you want to link the image to a URL, you can blend the image and link syntax: place your image URL in the exclamation points and suffix that with a colon and your URL. For example, an image at <code>http://myurl/image.png</code> that should link to <code>http://myurl/</code> should be written as <code>!http://myurl/image.png!:http://myurl/</code>.</p>',
      },
    ],
  },
]
