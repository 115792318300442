import './github/wiki/wiki-edit'
import './github/wiki/gollum'
import './github/wiki/legacy'
import {ASCIIDoc, ASCIIDocHelp} from './github/wiki/editor/langs/asciidoc'
import {GollumEditor, defineHelp, defineLanguage, replaceSelection} from './github/wiki/editor/gollum-editor'
import {MarkDown, MarkDownHelp} from './github/wiki/editor/langs/markdown'
import {OrgMode, OrgModeHelp} from './github/wiki/editor/langs/org'
import {Pod, PodHelp} from './github/wiki/editor/langs/pod'
import {Textile, TextileHelp} from './github/wiki/editor/langs/textile'
import {Creole} from './github/wiki/editor/langs/creole'
import {Dialog} from './github/wiki/gollum-dialog'
import {RDoc} from './github/wiki/editor/langs/rdoc'

// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

observe('#gollum-editor', function (el) {
  GollumEditor({
    NewFile: el.classList.contains('create'),
  })

  toggleMarkdownEditorMode()
})

// Internal Wikilanguage Functions
// @ts-expect-error WikiLanguage is not defined on typings
GollumEditor.WikiLanguage = {
  'function-internal-link': {
    exec() {
      return Dialog.init({
        title: 'Insert Wiki Link',
        fields: [
          {
            id: 'name',
            name: 'Link Name',
            type: 'text',
          },
        ],
        OK(res) {
          const r = res.name ? `[[${res.name}]]` : ''
          return replaceSelection(r)
        },
      })
    },
  },
}

// Rotates the triangle for table of content headers in the sidebar
on('click', '.js-wiki-sidebar-toc-toggle-chevron-button', function (event) {
  event.currentTarget
    .querySelector('.js-wiki-sidebar-toc-toggle-chevron')
    ?.classList?.toggle('js-wiki-sidebar-toc-toggle-chevron-open')
})

// Rotates the triangle for pages section header in the sidebar
on('click', '.js-wiki-toggle-collapse', function (event) {
  event.currentTarget
    .querySelector('.js-wiki-sidebar-pages-toggle-chevron')
    ?.classList?.toggle('js-wiki-sidebar-pages-toggle-chevron-open')
})

observe('.js-wiki-sidebar-toc-fragment', function (el) {
  function toggleHidden(element: HTMLElement, hidden: boolean) {
    if (hidden) {
      element.setAttribute('hidden', '')
    } else {
      element.removeAttribute('hidden')
    }
  }
  function toggleSpinner(loading: boolean, parentElement: HTMLElement | null) {
    if (parentElement) {
      const chevron = parentElement.querySelector('.js-wiki-sidebar-toc-toggle-chevron') as HTMLElement
      const spinner = parentElement.querySelector('.js-wiki-sidebar-toc-spinner') as HTMLElement

      toggleHidden(chevron, loading)
      toggleHidden(spinner, !loading)
    }
  }

  el.addEventListener('loadstart', e => toggleSpinner(true, (e.currentTarget as HTMLElement).parentElement))
  el.addEventListener('include-fragment-replace', e => {
    const currentTarget = e.currentTarget as HTMLElement
    if (!currentTarget) {
      return
    }

    toggleSpinner(false, currentTarget.parentElement)

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const fragment: HTMLElement | null = (e as any).detail?.fragment
    if (!fragment || fragment.textContent !== '') return

    const parentElement: HTMLElement | null = currentTarget.parentElement

    if (!parentElement) return

    const chevron = parentElement.querySelector('.js-wiki-sidebar-toc-toggle-chevron-button')

    if (!chevron) return

    chevron.classList.toggle('v-hidden')
  })
})

// Expand/collapse wiki table of contents
on('click', '.js-wiki-toggle-collapse', function (event) {
  const pagesBox = event.currentTarget.closest<HTMLElement>('.js-wiki-pages-box')!
  for (const toggleDisplay of pagesBox.querySelectorAll('.js-wiki-sidebar-toggle-display')) {
    /* eslint-disable-next-line github/no-d-none */
    toggleDisplay.classList.toggle('d-none')
  }
})

on('click', '.js-wiki-more-pages-link', function (event) {
  event.preventDefault()
  event.currentTarget.closest<HTMLElement>('.js-wiki-pages-box')!.classList.toggle('wiki-show-more')
})

// Append wiki format to preview request
on('preview:setup', '.js-previewable-comment-form', function (event) {
  const formatSelect = event.currentTarget.querySelector('#wiki_format')
  if (formatSelect) {
    const request = event.detail
    request.data.append('wiki_format', (formatSelect as HTMLInputElement).value)
  }
})

// Re-render the preview when changing format during preview
on('change', '#wiki_format', function (event) {
  const target = event.currentTarget
  const container = target.closest<HTMLElement>('.js-previewable-comment-form')!
  if (container.classList.contains('preview-selected')) {
    container.dispatchEvent(
      new CustomEvent('preview:render', {
        bubbles: true,
        cancelable: false,
      }),
    )
  }

  toggleMarkdownEditorMode()
})

function toggleMarkdownEditorMode() {
  const editor = document.querySelector('#gollum-editor-body')
  const select = document.querySelector<HTMLSelectElement>('#wiki_format')

  editor?.classList.toggle('js-paste-markdown', select?.value === 'markdown')
}

defineHelp('asciidoc', ASCIIDocHelp)
defineHelp('markdown', MarkDownHelp)
defineHelp('org', OrgModeHelp)
defineHelp('pod', PodHelp)
defineHelp('textile', TextileHelp)
defineLanguage('asciidoc', ASCIIDoc)
defineLanguage('creole', Creole)
defineLanguage('markdown', MarkDown)
defineLanguage('org', OrgMode)
defineLanguage('pod', Pod)
defineLanguage('rdoc', RDoc)
defineLanguage('textile', Textile)
