import {findElementByFragmentName} from '../fragment-target'
import hashChange from '../behaviors/hash-change'

// If a wiki page links to an anchor with a "#wiki-" prefix, and a named
// anchor does not exist on the page, then remove the prefix.
// For example: /github/github/wiki#wiki-foo -> /github/github/wiki#foo
hashChange(function () {
  if (!document.querySelector('#wiki-wrapper')) return

  const match = window.location.hash.match(/^#(wiki-(.+))$/)
  if (!match) return

  const prefixed = match[1]!
  const unprefixed = match[2]!
  const prefixedAnchor = findElementByFragmentName(document, prefixed)
  if (!prefixedAnchor) {
    window.location.hash = unprefixed
  }
})
