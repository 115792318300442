// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import verifySsoSession from '../sso'

let formSubmitted = false
let timeout: number | undefined

function queueCheck(el: HTMLInputElement) {
  timeout = window.setTimeout(checkHeadCommit, 5000, el)
}

async function checkHeadCommit(el: HTMLInputElement) {
  const currentVersion = el.value
  const url = el.getAttribute('data-url')!
  await verifySsoSession()

  const response = await fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
  const headSha = await response.text()

  if (currentVersion === headSha) {
    queueCheck(el)
  } else if (!formSubmitted) {
    const submit = document.querySelector('#gollum-editor-submit')
    if (submit instanceof HTMLButtonElement) {
      submit.disabled = true
      /* eslint-disable-next-line github/no-d-none */
      document.querySelector<HTMLElement>('#gollum-error-message')!.classList.remove('d-none')
    }
  }
}

observe('#wiki-current-version', {
  constructor: HTMLInputElement,
  initialize(el) {
    queueCheck(el)
  },
  remove() {
    clearTimeout(timeout)
  },
})

on('click', '#gollum-editor-submit', function () {
  formSubmitted = true
})
