import {replaceSelection} from '../gollum-editor'

/*
 *  Markdown Language Definition.
 *
 *  A language definition for string manipulation operations, in this case
 *  for the Markdown, uh, markup language. Uses regexes for various functions
 *  by default. If regexes won't do and you need to do some serious
 *  manipulation, you can declare a function in the object instead.
 *
 *  @example
 *  'functionbar-id'  :   {
 *                          exec: function(text, selectedText) {
 *                                   functionStuffHere();
 *                                },
 *                          search: /somesearchregex/gi,
 *                          replace: 'replace text for RegExp.replace',
 *                          append: "just add this where the cursor is"
 *                         }
 *
 **/

export const RDoc = {
  'function-bold': {
    search: /([^\n]+)([\n\s]*)/g,
    replace: '((*$1*))$2',
  },
  'function-code': {
    search: /([^\n]+)([\n\s]*)/g,
    replace: '(({$1}))$2',
  },

  'function-ul': {
    search: /(.+)([\n]?)/gi,
    replace: '* $1$2',
  },

  'function-ol': {
    exec(txt: string, selText: string) {
      // split into lines
      let repText = ''
      const lines = selText.split('\n')
      const hasContent = /[\w]+/
      for (let i = 0; i < lines.length; i++) {
        if (hasContent.test(lines[i]!)) {
          repText += `(${(i + 1).toString()}) ${lines[i]}`
        }
      }
      replaceSelection(repText)
    },
  },

  'function-h1': {
    search: /(.+)([\n]?)/gi,
    replace: '= $1$2',
  },

  'function-h2': {
    search: /(.+)([\n]?)/gi,
    replace: '== $1$2',
  },

  'function-h3': {
    search: /(.+)([\n]?)/gi,
    replace: '=== $1$2',
  },
}
