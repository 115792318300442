type Field = {
  id: string
  name: string
  type: string
  help?: string
}

type ResultCallback = (bag: {[key: string]: string}) => void

type DialogOptions = {
  body?: string
  title: string
  fields: Field[]
  OK: ResultCallback
}

type Dialog = {
  markupCreated: boolean
  markup: string
  eventOK: (e: Event) => void
  eventCancel: (e: Event) => void
  eventKeydown: (e: KeyboardEvent) => void
  okEventCallback?: ResultCallback
  createFieldMarkup: (fields: Field[]) => string
  createFieldText: (field: Field) => string
  createMarkup: (title: string, body: string) => string
  init: (arg0: DialogOptions) => void
  hide: () => void
  show: () => void
  attachEvents: () => void
  detachEvents: () => void
  getDetailsElement: () => HTMLElement
}

export const Dialog: Dialog = {
  markupCreated: false,
  markup: '',

  attachEvents() {
    document.querySelector<HTMLElement>('#gollum-dialog-action-ok')!.addEventListener('click', Dialog.eventOK)
    document.querySelector<HTMLElement>('#gollum-dialog-action-cancel')!.addEventListener('click', Dialog.eventCancel)
    for (const input of document.querySelectorAll<HTMLElement>('#gollum-dialog-dialog input[type="text"]')) {
      input.addEventListener('keydown', Dialog.eventKeydown)
    }
  },

  detachEvents() {
    document.querySelector<HTMLElement>('#gollum-dialog-action-ok')!.removeEventListener('click', Dialog.eventOK)
    document
      .querySelector<HTMLElement>('#gollum-dialog-action-cancel')!
      .removeEventListener('click', Dialog.eventCancel)
  },

  createFieldMarkup(fieldArray: Field[]) {
    // eslint-disable-next-line github/unescaped-html-literal
    let fieldMarkup = '<fieldset>'
    for (let i = 0; i < fieldArray.length; i++) {
      if (typeof fieldArray[i] === 'object') {
        // eslint-disable-next-line github/unescaped-html-literal
        fieldMarkup += '<div>'
        switch (fieldArray[i]!.type) {
          // only text is supported for now
          case 'text':
            fieldMarkup += Dialog.createFieldText(fieldArray[i]!)
            break

          default:
            break
        }
        fieldMarkup += '</div>'
      }
    }
    fieldMarkup += '</fieldset>'
    return fieldMarkup
  },

  createFieldText(fieldAttributes: {type: string; name: string; id: string}) {
    let html = ''

    if (fieldAttributes.name) {
      // eslint-disable-next-line github/unescaped-html-literal
      html += '<label class="d-block mb-1"'
      if (fieldAttributes.id) {
        html += ` for="gollum-dialog-dialog-generated-field-${fieldAttributes.id}"`
      }
      html += `>${fieldAttributes.name}</label>`
    }

    // eslint-disable-next-line github/unescaped-html-literal
    html += '<input type="text" class="mb-3 input-block"'

    if (fieldAttributes.id) {
      html += ` name="${fieldAttributes.id}"`
      html += ` id="gollum-dialog-dialog-generated-field-${fieldAttributes.id}">`
    }

    return html
  },

  createMarkup(title: string, body: string) {
    Dialog.markupCreated = true
    return `
      <div id="gollum-dialog-dialog">
        <div class="Box-header">
          <h3 class="Box-title">${title}</h3>
        </div>
        <div class="Box-body overflow-auto">
          <div id="gollum-dialog-dialog-body">${body}</div>
          <div id="gollum-dialog-dialog-buttons" class="pt-3 border-top">
            <button type="button" id="gollum-dialog-action-cancel" class="ml-2 btn btn-sm float-right" data-close-dialog>Cancel</a>
            <button type="button" id="gollum-dialog-action-ok" class="btn btn-sm float-right" data-close-dialog>OK</a>
          </div>
        </div>
      </div>`
  },

  eventCancel(e: Event) {
    e.preventDefault()
    Dialog.hide()
  },

  eventOK(e: Event) {
    e.preventDefault()

    const results: {[key: string]: string} = {}
    for (const el of document.querySelectorAll<HTMLInputElement>('#gollum-dialog-dialog-body input')) {
      const name = el.getAttribute('name')
      if (name) {
        results[name] = el.value
      }
    }

    // Call ok event callback after toggle event to give focus back to text area
    Dialog.getDetailsElement().addEventListener(
      'toggle',
      function () {
        // pass them to okEventCallack if it exists (which it should)
        if (typeof Dialog.okEventCallback === 'function') {
          Dialog.okEventCallback(results)
        }
      },
      {once: true},
    )

    Dialog.hide()
  },

  eventKeydown(e: KeyboardEvent) {
    // TODO: Refactor to use data-hotkey
    /* eslint eslint-comments/no-use: off */
    /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
    if (e.key === 'Enter') {
      Dialog.eventOK(e)
    }
    /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
  },

  hide() {
    Dialog.markupCreated = false
    Dialog.getDetailsElement().removeAttribute('open')
    Dialog.detachEvents()
  },

  getDetailsElement() {
    return document.querySelector<HTMLElement>('.js-gollum-button-details')!
  },

  init(argObject: {body?: string; title: string; fields: Field[]; OK: ResultCallback}) {
    let title = ''
    let body = ''

    // bail out if necessary
    if (!argObject || typeof argObject != 'object') {
      return
    }

    if (argObject.body && typeof argObject.body === 'string') {
      // eslint-disable-next-line github/unescaped-html-literal
      body = `<p>${argObject.body}</p>`
    }

    // alright, build out fields
    if (argObject.fields && typeof argObject.fields === 'object') {
      body += Dialog.createFieldMarkup(argObject.fields)
    }

    if (argObject.title && typeof argObject.title === 'string') {
      title = argObject.title
    }

    Dialog.okEventCallback = argObject.OK
    Dialog.markup = Dialog.createMarkup(title, body)

    Dialog.show()

    Dialog.attachEvents()
    const input = document.querySelector('#gollum-dialog-dialog input[type="text"]')
    if (input instanceof HTMLInputElement) {
      input.autofocus = true
    }
  },

  show() {
    if (Dialog.markupCreated) {
      document.querySelector<HTMLElement>('.js-gollum-button-dialog')!.innerHTML = Dialog.markup
      Dialog.getDetailsElement().setAttribute('open', '')
    }
  },
}
